import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';

import { Query } from '@apollo/client/react/components';
import gql from "graphql-tag";

import { LoadingMessage } from 'components/LoadingMessage';
import { FormattedHTMLMessageFragment } from 'components/FormattedHTMLMessageFragment';

export const GET_ONBOARDING_REQUEST = gql`
  query onboardingRequest {
    onboardingRequest {
      id
      device {
        id
        deviceId
        location
        isAlive
        firmwareStatus
        configIsApplied
        latestDeployment{
          status
          startTime
          endTime
          downloadStartTime
          downloadProgress
          rebootStartTime
        }
      }
      organizationName
      ownerFirstname
      ownerLastname
      ownerEmail
      ownerPhonenumber
      adminFirstname
      adminLastname
      adminEmail
      adminPhonenumber
      wifiPrivateCreate
      wifiPrivateSecurity
      wifiPrivateSsid
      submitted
      finalized
      deviceLocation
      updateScheduleType
      updateScheduleCustomFrom
    }
  }
`;

export default class GetUserRequest extends Component {

  constructor(props) {
    super(props);

    this.state = {
      pollInterval: 1000,
    }
  }

	render() {
    return (
      <Query
        query={GET_ONBOARDING_REQUEST}
        pollInterval={this.state.pollInterval}
      >

        {({ loading, error, data, refetch, networkStatus, stopPolling }) => {
          if (networkStatus === 4) return "Refetching!";
          if (loading) return (<LoadingMessage/>);

          if(error) return (
            <Message
              negative
            >
              <FormattedHTMLMessageFragment id="generic.errors.errorOccured.title"/>
            </Message>
          );

          let childrenProps = {
            onboardingRequest: data.onboardingRequest,
            loading: loading,
            error: data && data.onboardingRequest && data.onboardingRequest.userError,
          }

          const deviceConfigApplied = data.onboardingRequest && data.onboardingRequest.device;

          // Disable pollInterval if onboardingRequest is finalized
          if((!data.onboardingRequest || (data.onboardingRequest.finalized && deviceConfigApplied.configIsApplied)) && this.state.pollInterval !== 0) {
            stopPolling();
          }

          if(this.props.children === undefined && !!this.props.render) {
          	return this.props.render(childrenProps);
          } else {
          	return React.cloneElement(this.props.children, childrenProps);
          }

        }}

      </Query>
    );
  }
};
