import gql from "graphql-tag";

export const GET_STATS_INCIDENTS = gql`
  query GET_STATS_INCIDENTS{
    management{
      statsOpenIncidents: incidents(
        status: [OPEN]
        page: 1,
        itemsPerPage: 1,
      ){
        pageInfo{
          count
        }
      }
      statsOpenIncidentsCritical: incidents(
        status: [OPEN],
        severities: [CRITICAL]
        page: 1,
        itemsPerPage: 1,
      ){
        pageInfo{
          count
        }
      }
      statsOpenIncidentsHigh: incidents(
        status: [OPEN],
        severities: [HIGH]
        page: 1,
        itemsPerPage: 1,
      ){
        pageInfo{
          count
        }
      }
      statsOpenIncidentsModerate: incidents(
        status: [OPEN],
        severities: [MODERATE]
        page: 1,
        itemsPerPage: 1,
      ){
        pageInfo{
          count
        }
      }
      statsOpenIncidentsService: incidents(
        status: [OPEN],
        categories: [SERVICE]
        page: 1,
        itemsPerPage: 1,
      ){
        pageInfo{
          count
        }
      }
      statsOpenIncidentsFirmware: incidents(
        status: [OPEN],
        categories: [FIRMWARE]
        page: 1,
        itemsPerPage: 1,
      ){
        pageInfo{
          count
        }
      }
      statsOpenIncidentsNetwork: incidents(
        status: [OPEN],
        categories: [NETWORK]
        page: 1,
        itemsPerPage: 1,
      ){
        pageInfo{
          count
        }
      }
      statsOpenIncidentsThreat: incidents(
        status: [OPEN],
        categories: [THREAT]
        page: 1,
        itemsPerPage: 1,
      ){
        pageInfo{
          count
        }
      }
    }
}`;
export const GET_STATS = gql`
  query GET_STATS{
    management{
      statsAccount: organizations(
        page: 1,
        itemsPerPage: 100,
        hasInventory: false
      ){
        pageInfo{
          count
        }
        results{
          name
          id
          slug
          devicesOnline: devices(online: true){
            pageInfo{
              count
            }
          }
          devicesOffline: devices(online: false, inventoryStatus: IN_USE){
            pageInfo{
              count
            }
          }
          devicesNotInUse: devices(inventoryStatus: NOT_IN_USE){
            pageInfo{
              count
            }
            results{
              id
              location
              isAlive
              inventoryStatus
              deviceId
              onlineSince
              offlineSince
              rebootingSince
              operatingMode
              latestDeployment{
                downloadProgress
              }
              isRebooting
              firmwareStatus              
            }
          }
          networks{
            id
            location
            devices{
              id
              location
              isAlive
              deviceId
              onlineSince
              offlineSince
              rebootingSince
              operatingMode
              latestDeployment{
                downloadProgress
              }
              isRebooting
              firmwareStatus
            }
          }
        }     
      }
      statsNetworks: networks(
        page: 1,
        itemsPerPage: 100,
      ){
        pageInfo{
          count
        }
        results{
          location
          id
          countAllDevices: countDevices
          countOfflineDevices: countDevices(alive: false)
          countOnlineDevices: countDevices(alive: true)
          countAllHosts: countHosts(excludeNetworkDevices: false, byLastConnection: true)
          countAllSsids: countSsids
          countStoppedSsid: countSsids(inStatus: [DISABLED])
          countOfflineSsid: countSsids(inStatus: [NO_AVAILABLE_RADIO, OFFLINE_NO_RADIO, OFFLINE])
          countOnlineSsid: countSsids(inStatus: [ONLINE])
          countPartialSsid: countSsids(inStatus: [PARTIAL])
          countIncidents
        }
      }
    }
}`;

export const GET_STATS_DISKS = gql`
  query GET_STATS_DISKS{
    management {
      critical:disks(usage:[OS_DATA, OS_DATA_AND_CONTAINER_STORAGE, CONTAINER_STORAGE, STORAGE, NONE], availableSpaceLevel:CRITICAL) {
        pageInfo{count}
      }
      warning:disks(usage:[OS_DATA, OS_DATA_AND_CONTAINER_STORAGE, CONTAINER_STORAGE, STORAGE, NONE], availableSpaceLevel:WARNING) {
        pageInfo{count}
      }
      moderate:disks(usage:[OS_DATA, OS_DATA_AND_CONTAINER_STORAGE, CONTAINER_STORAGE, STORAGE, NONE], availableSpaceLevel:MODERATE) {
        pageInfo{count}
      }
      ok:disks(usage:[OS_DATA, OS_DATA_AND_CONTAINER_STORAGE, CONTAINER_STORAGE, STORAGE, NONE], availableSpaceLevel:OK) {
        pageInfo{count}
      }
    }
  }
`