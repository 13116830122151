import gql from "graphql-tag";


export const GET_MANAGEMENT_DNS_HOST = gql`
  query GET_MANAGEMENT_DNS_HOST(
		$direction: Direction
		$domainName: ShortString
		$groupByPrivateSuffix: Boolean
		$hostId: UUID
		$itemsPerPage: Int
		$orderBy: HostDomainOrderBy
		$page: Int
		$privateSuffix: ShortString
		$requestTimeFrom: DateTime
		$requestTimeTo: DateTime
		$search: ShortString
    $networkId: UUID,
    $organizationSlug: ShortString,
  ){
    management {
      dns {
	      statsByHost(
	      	direction: $direction
					domainName: $domainName
					groupByPrivateSuffix: $groupByPrivateSuffix
					hostId: $hostId
					page: $page
					itemsPerPage: $itemsPerPage
					orderBy: $orderBy
					privateSuffix: $privateSuffix
					requestTimeFrom: $requestTimeFrom
					requestTimeTo: $requestTimeTo
					search: $search
	        networkId: $networkId,
	        organizationSlug: $organizationSlug,
	      ) {
	        pageInfo {
	          count
	          numPages
	          itemsPerPage
	        }
	        results {
	          domain {id}
	          host {
	          	id
	          	displayName
	          	macAddr
	          	lastConnection{
	          		isOnline
	          	}
	        	}
	          countRequests
	          firstSeen
	          lastSeen
	        }
	      }
	    }
    }
  }
`;

export const GET_MANAGEMENT_DNS_DOMAIN = gql`
  query GET_MANAGEMENT_DNS_DOMAIN(
		$search: ShortString
		$requestTimeFrom: DateTime
		$requestTimeTo: DateTime
		$privateSuffix: ShortString
		$groupByPrivateSuffix: Boolean
		$orderBy: DomainOrderBy
		$direction: Direction
		$page: Int
		$itemsPerPage: Int
    $networkId: UUID,
    $organizationSlug: ShortString,
  ){
    management {
      dns {
	      statsByDomain(
	      	search: $search
					requestTimeFrom: $requestTimeFrom
					requestTimeTo: $requestTimeTo
					privateSuffix: $privateSuffix
					groupByPrivateSuffix: $groupByPrivateSuffix
					orderBy: $orderBy
					direction: $direction
					page: $page
					itemsPerPage: $itemsPerPage
	        networkId: $networkId,
	        organizationSlug: $organizationSlug,
	      ) {
	        pageInfo {
	          count
	          numPages
	          itemsPerPage
	        }
	        results {
	          domain {
	          	id
	        	}
	          countRequests
	          firstSeen
	          lastSeen
	        }
	      }
	    }
    }
  }
`;

export const GET_MANAGEMENT_DNS_REQUEST = gql`
  query GET_MANAGEMENT_DNS_REQUEST(
		$orderBy: QueryLogOrderBy
		$direction: Direction
		$page: Int
		$itemsPerPage: Int
		$search: ShortString
		$hostId: UUID
		$responseCodes: [DNSResponseCodes]
		$requestTimeFrom: DateTime
		$requestTimeTo: DateTime
		$domainName: ShortString
		$privateSuffix: ShortString
		$queryTypes: [DNSCommonQueryTypesEnum]
    $networkId: UUID,
    $organizationSlug: ShortString,
  ){
    management {
      dns {
      	logs(
      		orderBy: $orderBy
					direction: $direction
					page: $page
					itemsPerPage: $itemsPerPage
					search: $search
					hostId: $hostId
					responseCodes: $responseCodes
					requestTimeFrom: $requestTimeFrom
					requestTimeTo: $requestTimeTo
					domainName: $domainName
					privateSuffix: $privateSuffix
					queryTypes: $queryTypes
	        networkId: $networkId,
	        organizationSlug: $organizationSlug,
      	) {
	        pageInfo {
	          count
	          numPages
	          itemsPerPage
	        }
	        results {
	        	id
	        	clientIp
	          timestamp
	          query {
	          	id
	            qname { 
	            	id,
	            	name,
	            	privateSuffix{
	            		id
	            		name
	            	}
	            },
	            qtype,
	            responseCode,
	            records {name, type, value, parseResult }
	          }
	          elapsed
	          host {
	          	id
	          	displayName
	          	macAddr
	          	lastConnection{
	          		isOnline
	          	}
	        	}
	          vlan{
	          	vid
	          	id
	          	name
	          }
	        }
	      }
	    }
    }
  }
`;