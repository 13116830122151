import React, { Component } from 'react';
import store from 'tools/SimpleStore.js';

// Deal with hidden tab : stop polling and disable app
let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support 
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

export function withTabActive(OriginalComponent) {

	return class extends Component {
	  constructor(props){
	    super(props);

	    this.state = {
	      isTabHidden: false,
	    }
	  }

	  componentDidMount() {
	    document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
	  }

	  componentWillUnmount()  {
	    document.removeEventListener(visibilityChange, this.handleVisibilityChange);
	  }

	  handleVisibilityChange = () => {
	  	const prevIsTabHidden = this.state.isTabHidden;
	    this.setState({isTabHidden:document[hidden]}, () => {
	    	if(!this.state.isTabHidden && prevIsTabHidden){
	    		fetch(`${window.location.origin}/version.json`, {cache: "no-store"})
	    		.then(response  => {
	    			if(response.ok){
	    				return response.json()
	    			}
	    		})
    			.then((json) => {
    				console.log(json, store("sentinelc_version"))
    				if(json && json !== store("sentinelc_version")){
    					store("sentinelc_version", json)
    					window.location.reload();
    				}
    			})    				
	    	}
	    });
	  }
    render() {
       return <OriginalComponent {...this.props} isTabHidden={this.state.isTabHidden} />
    }
	}
}