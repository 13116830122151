import React from "react"

export const ViewportContext = React.createContext({});

export const ViewportProvider = ({ children }) => {
  // This is the exact same logic that we previously had in our hook

  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <ViewportContext.Provider value={{ width, height,
      isMobile: window.matchMedia('(max-width: 519px)').matches,
      isTablet: window.matchMedia('(min-width: 520px) and (max-width: 767px)').matches,
      isTabletAndIpad: window.matchMedia('(min-width: 520px) and (max-width: 1024px)').matches,
      isLteTablet: window.matchMedia('(max-width: 767px)').matches,
      isDesktop: window.matchMedia('(min-width: 768px)').matches,
      isLargeScreen: window.matchMedia('(min-width: 992px)').matches,
    }}>
      {children}
    </ViewportContext.Provider>
  );
};

export const useViewport = () => {
  return React.useContext(ViewportContext);
}