import React, { Component } from 'react';
import { Loader, Message, Modal } from 'semantic-ui-react';
import _ from 'lodash';
import store from 'tools/SimpleStore.js';

import { withTabActive } from 'components/WithTabActive';
import { ButtonGroup } from 'components/Buttons/ButtonGroup';
import { PrimaryButton } from 'components/Buttons/PrimaryButton';
import { FormattedHTMLMessageFragment } from 'components/FormattedHTMLMessageFragment';

export class ErrorOfflineBase extends Component {

  logout(e) {
    e.preventDefault();
    window.keycloak.logout({redirectUri:window.origin + "/", locale: store("locale") || "en"});
  }

  render() {
    if(this.props.isTabHidden) return null;

    if(_.get(this.props.error,"statusCode") === 401 || _.get(this.props.error,"networkError.statusCode") === 401){
    
      return(
        <Modal
          open={true}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Message size='small' negative className="fixed bottom" compact>
            <Message.Content>
              <FormattedHTMLMessageFragment id="generic.errors.unauthorized" />
              <ButtonGroup
                colRight={(
                  <PrimaryButton i18nId="generic.buttons.login" onClick={this.logout}/>
                )}                
              />
            </Message.Content>
          </Message>
        </Modal>
      );

    }
    
    return(
      <Modal
        open={true}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Message size='small' negative className="fixed bottom" compact>
          <Message.Content>
            <FormattedHTMLMessageFragment id="generic.errors.connectionLost" />
            <Loader active inline='centered' className="red" />
          </Message.Content>
        </Message>
      </Modal>
    );
  }
}

export const ErrorOffline = withTabActive(ErrorOfflineBase);