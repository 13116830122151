import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Redirect } from "react-router-dom";
import { Checkbox, Header, Menu, Segment } from 'semantic-ui-react';
import _ from "lodash";

import { getPages, getSupportUrls} from 'tools/getUrls.js';
import { OnBoardingLayout } from 'layouts/OnBoardingLayout';

import { PrimaryButton } from 'components/Buttons/PrimaryButton';
import { ButtonGroup } from 'components/Buttons/ButtonGroup';
import { Icon } from 'components/Icon';
import { UserPrefsProvider } from 'components/UserPrefsProvider';
import { LoadingMessage } from 'components/LoadingMessage';

import viewAdminImg from 'styles/semantic/dist/themes/sentinelC/assets/images/viewAdmin.png';
import viewAppImg from 'styles/semantic/dist/themes/sentinelC/assets/images/viewApp.png';

export function SelectPref(){
  const intl = useIntl();
  const history  = useHistory();

  const [prefsLoaded, setPrefLoaded] = useState(false)

  return(
    <UserPrefsProvider
      onCompleted={(data, userUIPrefs) => {

        const prefs = _.get(data,"uiPreference.value") ? JSON.parse(_.get(data,"uiPreference.value")) : _.get(userUIPrefs,"value");
        const homeLanding = _.get(prefs,"prefs.homeLanding");
        
        if(homeLanding === "app" && _.get(history, "location.pathname") === "/") history.push(getPages().selectOrganization)
        if(homeLanding === "admin" && _.get(history, "location.pathname") === "/") history.push(getSupportUrls().main)
        setPrefLoaded(true)
      }}
      render={userPrefsProps => {

        const changeField = _.get(userPrefsProps,"changeField");
        const homeLanding = _.get(userPrefsProps,"data.value.prefs.homeLanding")

        if(homeLanding === "app") return <Redirect to="/dashboard" />
        if(homeLanding === "admin") return <Redirect to="/support" />
        if(!prefsLoaded) return <LoadingMessage />
        
        return(
          <OnBoardingLayout>
            <Segment>
              <ButtonGroup
                className="responsive"
                colLeft={(
                  <div>
                    <h1>
                      {intl.formatMessage({id:"homepage.pref.title"})}
                    </h1>
                    <p>{intl.formatMessage({id:"homepage.pref.desc"})}</p>
                  </div>
                )}
                colRight={(
                  <Menu vertical inverted>
                      <Menu.Item className="flex" style={{textAlign:"right"}}>
                        <Icon color="grey" name="user" circular inverted />
                      </Menu.Item>
                      <Menu.Item className="flex">
                        <span style={{verticalAlign: "middle"}}>{intl.formatMessage({id:"generic.settings.homeLanding"})}</span>
                        {" "}
                        <Checkbox 
                          toggle
                          className="small"
                          style={{verticalAlign: "middle"}}
                          disabled={true}
                          checked={true}
                        />
                      </Menu.Item>
                  </Menu>
                )}
              />
            </Segment>
            <br/>
            <div className="by2">
              <div className="by2-50">
                <Header inverted as='h2' attached='top'>
                  {intl.formatMessage({id:"backOffice.users.regular"})}
                </Header>
                <Segment attached>
                  <p className="as-h3 strong">{intl.formatMessage({id:"backOffice.users.regular.desc"})}</p>
                  <img style={{width: "100%"}} alt="" src={viewAppImg}/>
                  <PrimaryButton fluid onClick={() => changeField("homeLanding", "app")}>
                    {intl.formatMessage({id:"generic.buttons.choose"})}
                  </PrimaryButton>
                </Segment>
              </div>
              <div className="by2-50">
                <Header inverted as='h2' attached='top'>
                  {intl.formatMessage({id:"backOffice.users.expert"})}
                </Header>
                <Segment attached>
                  <p className="as-h3 strong">{intl.formatMessage({id:"backOffice.users.expert.desc"})}</p>
                  <img style={{width: "100%"}} alt="" src={viewAdminImg}/>
                  <PrimaryButton fluid onClick={() => changeField("homeLanding", "admin")}>
                    {intl.formatMessage({id:"generic.buttons.choose"})}
                  </PrimaryButton>
                </Segment>
              </div>
            </div>
          </OnBoardingLayout>
        );
      }}
    />
  )
}