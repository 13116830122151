import gql from "graphql-tag";

export const GET_DISKS = gql`
  query GET_DISKS(
    $page: Int
		$itemsPerPage: Int
		$search: ShortString
		$orderBy: DiskOrderBy
		$direction: Direction
		$organizationSlug: ShortString
		$networkId: UUID
		$diskStatus: [DiskStatusEnum!]
		$usage: [DiskUsageEnum!]
		$diskType: [BlockDeviceTypeEnum!]
		$availableSpaceLevel: [AvailableSpaceEnum!]
  ){
	  management {
	    disks(
	    	page: $page
				itemsPerPage: $itemsPerPage
				orderBy: $orderBy
				direction: $direction
				organizationSlug: $organizationSlug
				networkId: $networkId
				search: $search
				diskStatus: $diskStatus
				usage: $usage
				diskType: $diskType
				availableSpaceLevel: $availableSpaceLevel
	    ) {
	      pageInfo {
	        count
	        currentPage
					itemsPerPage
	      }
	      results {
	        id
	        device {
	          deviceId
	          location
	          isAlive
		        network{
		          id
		          location
		          organization{
				        slug
				        id
				        name
				      }
		        }
	        }
	        model
	        parentDiskName
	        name
	        partNumber
	        linuxId
	        
        	hasVolumes
	        status
	        usage
	        availableSpaceLevel
	        bytesTotal
	        bytesUsed
	      
	      }
	    }
	  }
  }
` 