import React, {Component} from 'react';

export class ButtonGroup extends Component {

	render() {
	  const {
	  	className,
	  	alignButtons,
	  	children,
	  	colLeft = null,
	  	colRight = null,
	  	error,
	  	inversePosition,
	  	...rest
	  } = this.props;

	  if ((colLeft ===  null || colRight ===  null) && colLeft !== colRight) {
	  	return (
	  		<div
			    {...rest}
			    className={['button-group', inversePosition ? 'switch' : '', `aligned-content ${alignButtons ? alignButtons : (colLeft === null ? 'right' : 'left')}`,className ? className : ""].join(' ')}
			  >
			  	{colLeft || colRight}
			  </div>
		  );
	  } else if (colLeft !==  null && colRight !==  null) {
	  	return (
	  		<div
			    {...rest}
			    className={['button-group', inversePosition ? 'switch' : '', `aligned-content columns`,className ? className : ""].join(' ')}
			  >
			  	<div className="column left">{colLeft}</div>
			  	<div className="column right">{colRight}</div>
			  </div>
		  );
	  }
	  return (
		  <div
		    {...rest}
		    className={['button-group', inversePosition ? 'switch' : '', alignButtons ? `aligned-content ${alignButtons}` : '',className ? className : ""].join(' ')}
		  >
		  	{children}
		  </div>
		);
	}
}