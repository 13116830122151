import React, { Component, Fragment } from 'react';

import Logo from 'components/Logo';
import { Menu } from 'semantic-ui-react';

import { LanguageSelector } from 'components/LanguageSelector';

export class OnBoardingHeader extends Component {
  render(){
    const {
      logo,
      title,
      backButton,
    } = this.props;
    return(
      <Fragment>
        <Menu as="header" className="onboarding-header" inverted borderless >
          <Menu.Item>
          {logo || (
            <Logo
              alt="SentinelC"
              full
              inverse
              size="small"
              transparent
            />
          )}
          </Menu.Item>
          {title && (
            <Menu.Item><h1>{title}</h1></Menu.Item>
          )}
          <LanguageSelector list code={true}/>
        </Menu>
        {backButton}
      </Fragment>
    );
  };
}