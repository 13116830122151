import gql from "graphql-tag";

export const GET_PREFERENCES = gql`
	query GET_PREFERENCES(
		$key: String!, 
		$scope: UIPreferenceScopeEnum!
	  $projectId: UUID
	) {
    uiPreference(
	    key: $key, 
	    scope: $scope
	    projectId: $projectId
    ) {
      key
      scope
      value
    }
}
`;