import React, { Component } from 'react';
import { Icon as SemanticIcon } from 'semantic-ui-react';
import { ALL_ICONS_IN_ALL_CONTEXTS } from '../../../node_modules/semantic-ui-react/src/lib/SUI.js';


export class Icon extends Component{
	static defaultProps = {
		forced: null,
		customIcon: null,
	}

	useKeyOnly = (val, key) => val && key;
	useValueAndKey = (val, key) => val && val !== true && `${val} ${key}`;
	useKeyOrValueAndKey = (val, key) => val && (val === true ? key : `${val} ${key}`);

	render(){
		if(ALL_ICONS_IN_ALL_CONTEXTS.indexOf(this.props.name) >= 0 || this.props.forced) {

      const {
        customIcon,
        onClick,
        ...rest
      } = this.props
			return(
				<SemanticIcon onClick={onClick} {...rest}/>
			);
		}
		const {
      bordered,
      circular,
      className,
      color,
      corner,
      customIcon,
      disabled,
      fitted,
      flipped,
      inverted,
      link,
      loading,
      name,
      rotated,
      size,
      onClick,
    } = this.props

		const classes = [
      color,
      name,
      size,
      this.useKeyOnly(bordered, 'bordered'),
      this.useKeyOnly(circular, 'circular'),
      this.useKeyOnly(disabled, 'disabled'),
      this.useKeyOnly(fitted, 'fitted'),
      this.useKeyOnly(inverted, 'inverted'),
      this.useKeyOnly(link, 'link'),
      this.useKeyOnly(loading, 'loading'),
      this.useKeyOrValueAndKey(corner, 'corner'),
      this.useValueAndKey(flipped, 'flipped'),
      this.useValueAndKey(rotated, 'rotated'),
      'icon',
      className,
      "extended-icon",
      customIcon ? "custom-icon" : "",
    ];

    return (<i aria-hidden="true" className={classes.join(' ').trim()} onClick={onClick}/>);
  }
}