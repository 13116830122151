import React, { Component, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { Message, Modal } from 'semantic-ui-react';
import store from 'tools/SimpleStore.js';

import { PrimaryButton } from 'components/Buttons/PrimaryButton';
import { FormattedHTMLMessageFragment } from 'components/FormattedHTMLMessageFragment';

class ErrorTokenBase extends Component {

  constructor(props){
    super(props);

    this.state = {
      showModal: false,
    }
  }

  componentDidMount(){
    const t = this;
    if (window.keycloak.authenticated) {
      window.renewTokenInterval = setInterval(function(){
        t.updateToken(t.disconnectUser);
      },3000);

      window.keycloak.onAuthRefreshError = this.disconnectUser;
    }
  }

  updateToken = (callback) => {
    const {keycloak} = window;

    keycloak.updateToken(14).then(function(refreshed) {
      if (refreshed) {
        //console.info('Token was successfully refreshed: ', keycloak.tokenParsed);
        if(typeof callback === "function"){
          callback();
        } /*else {
          console.log(typeof callback)
        }*/
      } /*else {
        console.warn('Token not refreshed, valid for ' + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
      }*/
    })/*.error(function() {
        console.warn('Token refresh has failed');
    });*/

  }

  disconnectUser = () => {
    clearInterval(window.renewTokenInterval);
    this.setState({showModal: true, redirectUri: window.location.href});
  }

  formatTime = (ms, onlySeconds) => {
    const seconds = Math.floor((ms/1000)%60);
    const minutes = (Math.floor(ms/1000) - seconds) / 60;

    if(onlySeconds) return this.props.intl.formatMessage({id:"generic.seconds"},{seconds: seconds});

    return `(${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds})`;
  }

  render() {
    const {showModal, redirectUri} = this.state;

    return(
      <Fragment>
        {this.props.children}
        <Modal
          open={showModal}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Message size='small' negative className="fixed bottom" compact>
            <Message.Content>
              <p><FormattedHTMLMessageFragment id="generic.errors.tokenExpired" /></p>            
              <PrimaryButton
                size="small"
                i18nId="generic.errors.tokenExpired.btn"
                onClick={() => {
                  window.keycloak.logout({redirectUri, locale: store("locale") || "en"});
                }}
              />
            </Message.Content>
          </Message>
        </Modal>
      </Fragment>
    );
  }
}

export const ErrorToken = injectIntl(ErrorTokenBase);