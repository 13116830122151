import React, { Component } from 'react';
import { Mutation } from '@apollo/client/react/components'
import { ErrorBoundary } from 'react-error-boundary';

import GlobalErrorContext from 'contexts/GlobalErrorContext';
import { ErrorExpandable } from 'components/ErrorExpandable';


export default class MutationWithError extends Component {
  render(){
    return (
      <ErrorBoundary FallbackComponent={ErrorExpandable}>
        <GlobalErrorContext.Consumer>
          {({setError}) => (
            <Mutation
              {...this.props}
              onError={(error) => {
                  console.log('Mutation onError : ', error);
                  console.log('OnError details : ', Object.keys(error),
                  error.graphQLErrors,
                  error.networkError,
                  error.message,
                  error.extraInfo
                );
                setError(error.toString());
                if(this.props.onError) this.props.onError(error);
              }}
            />
          )}
        </GlobalErrorContext.Consumer>
      </ErrorBoundary>
    );
  }
}
