import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

export class FormattedHTMLMessageFragment extends Component {

  render(){

    return (
        <FormattedMessage {...this.props}>
          {content => {return content;}}
        </FormattedMessage>
    );
  }
}