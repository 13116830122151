import { Component } from "react";
import { withRouter } from "react-router";

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      var container = this.props.selector ? document.querySelector(this.props.selector) : window;
      container.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)