import gql from "graphql-tag";

//networkLocation: reportLine(key:"NETWORK_LOCATION")

export const GET_MANAGEMENT_INCIDENTS = gql`
  query GET_MANAGEMENT_INCIDENTS(
    $page: Int,
    $itemsPerPage: Int,
    $search: ShortString,
    $severities: [IncidentSeverity!],
    $status: [IncidentStatusEnum!],
    $categories: [IncidentCategoryEnum!],
    $fromDate: DateTime,
    $toDate: DateTime,
    $direction: Direction,
    $orderBy: IncidentOrderBy,
    $organizationSlug: ShortString,
    $deviceId: String,
    $networkId: UUID,
    $hostId: UUID,
  ){    
  	management{
	    incidents(
	    	page: $page,
	    	itemsPerPage: $itemsPerPage,
        search: $search,
        severities: $severities,
        status: $status,
        categories: $categories,
        fromDate: $fromDate,
        toDate: $toDate,
        direction: $direction,
        orderBy: $orderBy,
        organizationSlug: $organizationSlug,
        deviceId: $deviceId,
        networkId: $networkId,
        hostId: $hostId,
    	){
    		pageInfo{
    			count
    			itemsPerPage
    			maxItemsPerPage
    			currentPage
          numPages
    		}
    		results{
          id
          created
          severity
          category
          organization{
            id
            name
            slug
          }
          network {
            id
            location
          }    
          networkLocation: reportLine(key:"NETWORK_LOCATION")
          summary
          status
          assignedOn
          assignee{
            id
            firstname
            lastname
            email
            isPrivileged
            isAuditor
          }
    		}
	    }
  	}
  }`;

export const GET_MANAGEMENT_INCIDENT = gql`
  query GET_MANAGEMENT_INCIDENT(
    $incidentId: String!,
  ){    
    management{
      users(permissions: [PRIVILEGED], isActive:true) {
        pageInfo {
          count
        }
        results {
          id
          firstname
          lastname
          email
        }
      }
      incident(
        incidentId:$incidentId
      ){
        id
        created
        description
        severity
        type
        category
        host{
          id
        }
        network{
          id
          location
          contact{
            firstname
            lastname
            email
          }
        }
        organization{
          id
          name
          slug
          contact{
            firstname
            lastname
            email
          }
        }
        pod{
          id
        }
        summary
        status
        reportLines{
          key
          value
          type
        }
        assignee{
          id
          firstname
          lastname
          isPrivileged
        }
        device{
          deviceId
          location
          currentFirmware{
            version
          }
          id
        }
        task{
          id
          status
        }
        lastUpdate{
          timestamp
          author{
            id
            firstname
            lastname
            email
          }
        }
        updates{
          id
          timestamp
          log
        }
        supportNotes{
          id
          timestamp
          author{
            id
            firstname
            lastname
            email
          }
          text
        }
      }
    }
  }`;
