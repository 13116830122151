import gql from "graphql-tag";

export const GET_GALLERY_TAGS = gql`
  query GET_GALLERY_TAGS(
    $slug: ShortString!,
    $hostsPaginationPage: Int
		$devicesPaginationPage: Int

    $includeNetworks: Boolean!,

    $includeHosts: Boolean!,
    $nameSearchHosts: ShortString,

    $includeDevices: Boolean!,
    $nameSearchDevices: ShortString,
  ){
    management{
      organization(organizationSlug: $slug){
        slug
        id
        name

        networks @include(if: $includeNetworks){
          id
          location
        }
      }

      hosts(
        organizationSlug: $slug
        page:$hostsPaginationPage,
        itemsPerPage : 100,
        nameSearch: $nameSearchHosts,
        orderBy: DISPLAY_NAME
        direction: ASC
      ) @include(if: $includeHosts){
        pageInfo {
          count
          currentPage
          maxItemsPerPage
          itemsPerPage
          numPages
        }
        results{
          id
          macAddr
          hostname
          displayName
        }
      }
      devices(
        organizationSlug: $slug
        page:$devicesPaginationPage,
        itemsPerPage : 100,
        nameSearch: $nameSearchDevices,
        orderBy: DEVICE_ID
        direction: ASC
      ) @include(if: $includeDevices){
        pageInfo {
          count
          currentPage
          maxItemsPerPage
          itemsPerPage
          numPages
        }
        results{
          id
          deviceId
          location
        }
      }
    }
  }`;


export const GET_GALLERY = gql`
  query GET_GALLERY(
    $networkId: UUID

    $includeHosts: Boolean!,
    $hostsPaginationPage: Int
    $nameSearchHosts: ShortString,

    $includeDevices: Boolean!,
    $devicesPaginationPage: Int
    $nameSearchDevices: ShortString,
  ){
    management{
      photos(networkId:$networkId) {
        id
        description
        network{
          id
          location
          organization{
            id
            slug
            name
          }
        }
        hosts {
          id
          displayName
        }
        devices {
          id
          deviceId
          location
          removed
        }
        uploadDate
        uploadedBy {
          id
          email
          firstname
          lastname
        }
        small
        medium
        large
      }

      hosts(
        networkId:$networkId
        page:$hostsPaginationPage,
        itemsPerPage : 100,
        nameSearch: $nameSearchHosts,
        orderBy: DISPLAY_NAME
        direction: ASC
      ) @include(if: $includeHosts){
        pageInfo {
          count
          currentPage
          maxItemsPerPage
          itemsPerPage
          numPages
        }
        results{
          id
          macAddr
          hostname
          displayName
        }
      }
      devices(
        networkId:$networkId
        page:$devicesPaginationPage,
        itemsPerPage : 100,
        nameSearch: $nameSearchDevices,
        orderBy: DEVICE_ID
        direction: ASC
      ) @include(if: $includeDevices){
        pageInfo {
          count
          currentPage
          maxItemsPerPage
          itemsPerPage
          numPages
        }
        results{
          id
          deviceId
          location
        }
      }
    }
  }`;