import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { withAlert } from 'react-alert';
import _ from 'lodash';

import { 
  UPDATE_PREFERENCES,
} from './PreferencesMutations';

import MutationWithError from 'components/MutationWithError';
import { FormattedHTMLMessageFragment } from 'components/FormattedHTMLMessageFragment';

export class PreferencesControllerBase extends Component {

  constructor(props){
    super(props);

    this.state = {
      variables: {},
    }
  }

  handleSubmit(taskRequest, variables) {
    this.setState({
      variables: variables,
    });

    taskRequest({variables});
  }

  onMutationCompleted = (data, returnObjectName) => {
    const {
      onCompleted,
      callback,
    } = this.props;

    if (data) {
      const e = data[returnObjectName].userError; 
      if (data[returnObjectName] && !e) {
        if(callback) {
          callback(data[returnObjectName]);
        }
        if(typeof onCompleted === "function") {
          onCompleted(data);
        }
      } else if(_.get(e,"code") === "FORBIDDEN") {
        this.props.alert.show(
          e.message, {
          type: "error",
        })
        if(typeof _.get(this.props,"onError") === "function"){
          this.props.onError(e);
        }
      }
    }
  }

  render() {

  	const {
      operation,
      render,
    } = this.props;

    let operationMutation = "";
  	let returnObjectName = "";

  	switch(operation){
  		default: returnObjectName = "uiPreferenceUpdate" ; operationMutation = UPDATE_PREFERENCES ; break;
  	}

    return (
      <MutationWithError
        mutation={operationMutation}
        onCompleted={(data) => {
          this.onMutationCompleted(data, returnObjectName)
        }}
        onError={(error) => {
          this.props.alert.show(
            <FormattedHTMLMessageFragment
              id={'generic.errors.message'}
            />, {
            type: 'error',
          })
        }}
      >
        {(taskRequest, { data, loading }) => {

          const childrenProps = {
            data: data,
            host: this.props.user,
            loading: loading,
            error: data && data[returnObjectName] && data[returnObjectName].userError,
            onSubmit:(values) => {
              this.handleSubmit(taskRequest, values);
            },
          }

          if (typeof render === "function") return render(childrenProps);
          return React.cloneElement(this.props.children, childrenProps);
        }}
      </MutationWithError>
    );
  }

}

export const PreferencesController = withAlert()(withRouter(PreferencesControllerBase));
