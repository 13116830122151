import gql from "graphql-tag";

export const GET_MANAGEMENT_WIFISURVEYS = gql`
  query GET_MANAGEMENT_WIFISURVEYS(
    $page: Int,
    $itemsPerPage: Int,
    $organizationSlug: ShortString,
    $networkId: UUID, 
		$deviceId: String, 
    $orderBy: WifiSurveyOrderBy,
    $direction: Direction,
    $search: ShortString,
    $fromDate: DateTime,
    $toDate: DateTime,
    $wifiSurveyIds: [UUID!],
    $hasRogue: Boolean,
    $bssId: ID,

    $askForPagination: Boolean!,
  ){    
  	management{    
  		wifiSurveys(
	    	page: $page
				itemsPerPage: $itemsPerPage
				organizationSlug: $organizationSlug
        networkId: $networkId,
				deviceId: $deviceId
				orderBy: $orderBy
				direction: $direction
				search: $search
				fromDate: $fromDate
				toDate: $toDate
				wifiSurveyIds: $wifiSurveyIds
				hasRogue: $hasRogue
        bssId: $bssId
  		) {
    		pageInfo @include(if: $askForPagination){
    			count
    			itemsPerPage
    			maxItemsPerPage
    			currentPage
    			numPages
    		}
  			results {
	        id
	        bssidCount
	        rogueCount
	        task{	     
	       		id   	
		        startTime
		        endTime
		        device{
		        	id
		        	deviceId
		        	location
              actions{
                action
                enabled
                disabledReason
              }  
              network{
                id
                location
              }
              organization{
                id
                slug
                name
              }
		        }
            requestedBy{
              id
              firstname
              lastname
              email
            }
	        }
	      }
  		}
  	}
  }
`;


export const GET_MANAGEMENT_WIFISURVEY = gql`
  query GET_MANAGEMENT_WIFISURVEY(
    $wifiSurveyId: UUID!,
  ){
    management{    
      wifiSurvey(
        wifiSurveyId: $wifiSurveyId
      ) {
        id
        reports{
          bss{
            bssid
            flags
            ssid
          }
          frequency
          isRogue
          linkedRogueId
          rogueRuleMatchType
          rogueRuleValue
          signal
          
        }
      }
    }
  }
`;