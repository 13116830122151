import gql from "graphql-tag";

export const GET_MANAGEMENT_SSID = gql`
  query GET_MANAGEMENT_SSID(
    $ssidId: UUID!,
    $networkId: UUID!,
  ){
    management{
      network(networkId: $networkId){
        id
        location
        organization{
          id
          name
          slug
        }
      }
      ssid(ssidId: $ssidId){
        name
        disabled
        dependentSsids{
          name
          id
        }
        id
        psk
        security
        type
        lastDisabled
        lastEnabled
        status{
          current
          scheduled
          since
        }
        vlan{
          id
          name
          accessPolicy
          description
          management
        }
        actions {
          action
          enabled
          disabledReason
        }
        network{
          id
          location
          organization{
            id
            name
            slug
          }
          devices{
            id
            location
            isAlive
            operatingMode
            maxSsids
            countSsidsEnabled: countSsids(inStatus: [ONLINE, PARTIAL])
            countAllSsids: countSsids
            actions{
              action
              enabled
              disabledReason
            }
            radios {
              macAddress
              configuredChannel{
                channel
                displayName
                availableTxPowers{
                  label
                  value
                  actual
                }
              }
              bands
              enabled
            }
          }
        }
        devices{
          id
          location
          isAlive
          radios {
            macAddress
            configuredChannel{
              channel
              displayName
              availableTxPowers{
                label
                value
                actual
              }
            }
            bands
            enabled
          }
        }
        schedule{
          enabled
          monday{
            type
            ranges{
              start
              end
            }
          }
          tuesday{
            type
            ranges{
              start
              end
            }
          }
          wednesday{
            type
            ranges{
              start
              end
            }
          }
          thursday{
            type
            ranges{
              start
              end
            }
          }
          friday{
            type
            ranges{
              start
              end
            }
          }
          saturday{
            type
            ranges{
              start
              end
            }
          }
          sunday{
            type
            ranges{
              start
              end
            }
          }
        }
      }
    }
  }`;

export const GET_MANAGEMENT_SSIDS = gql`
  query GET_MANAGEMENT_SSIDS(    
    $page: Int,
    $itemsPerPage: Int,
    $search: ShortString,
    $orderBy: SSIDOrderBy,
    $direction: Direction,
    $organizationSlug: ShortString,
    $networkId: UUID,
    $deviceId: String,

    $askForPagination: Boolean!,
  ){
    management{
      ssids(
        page: $page,
        itemsPerPage: $itemsPerPage,
        search: $search,
        orderBy: $orderBy,
        direction: $direction,
        organizationSlug: $organizationSlug,
        networkId: $networkId,
        deviceId: $deviceId,
      ){
        pageInfo @include(if: $askForPagination){
          count
          itemsPerPage
          maxItemsPerPage
          currentPage
        }
        results{
          countHostsOnline: countHosts(connectionStatus:ONLINE, byLastConnection: true)
          name
          disabled
          id
          psk
          security
          type
          lastDisabled
          lastEnabled
          status{
            current
            scheduled
            since
          }
          vlan{
            id
            name
          }
          actions {
            action
            enabled
            disabledReason
          }
          network{
            id
            location
            organization{
              id
              name
              slug
            }
          }
          devices{
            id
            location
            isAlive
            radios {
              macAddress
              configuredChannel{
                channel
                displayName
                availableTxPowers{
                  label
                  value
                  actual
                }
              }
              bands
              enabled
            }
          }
          schedule{
            enabled
            monday{
              type
              ranges{
                start
                end
              }
            }
            tuesday{
              type
              ranges{
                start
                end
              }
            }
            wednesday{
              type
              ranges{
                start
                end
              }
            }
            thursday{
              type
              ranges{
                start
                end
              }
            }
            friday{
              type
              ranges{
                start
                end
              }
            }
            saturday{
              type
              ranges{
                start
                end
              }
            }
            sunday{
              type
              ranges{
                start
                end
              }
            }
          }
        }
      }
    }
  }`;