import React from "react";
import _ from 'lodash';
import { Message, Popup } from 'semantic-ui-react';

import { FormattedHTMLMessageFragment } from 'components/FormattedHTMLMessageFragment';
import { Icon } from 'components/Icon';

export function NetworkError(props){
  if(!props.error) return null;
	if(!_.get(props,"error.networkError")) return null;
  if(window.isunloading) return (<Message size="mini" info ><FormattedHTMLMessageFragment id="generic.errors.message.unloading"/></Message>)
	return (
    <Message negative size={props.size || null}>
      <div>
        <strong><FormattedHTMLMessageFragment id='generic.errors.errorOccured.title'/></strong>
        {_.get(props,"error.networkError.message") && (
          <Popup hoverable
            on='hover'
            position="top center"
            size="small"
            trigger={(<Icon name="info circle" className="help-button" color="blue" link />)}
            content={_.get(props,"error.networkError.message")}
          /> 
        )}
      </div>
      {!_.get(props,"light") && (<FormattedHTMLMessageFragment id='generic.errors.errorOccured.detail'/>)}
    </Message>
	)
}