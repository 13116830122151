import gql from "graphql-tag";

export const GET_MANAGEMENT_SERVICE = gql`
	query GET_MANAGEMENT_SERVICE ($recipeId: ID!) {
  management {
    recipe(recipeId: $recipeId) {
      id
      name
      countPods
      displayName
      description
      documentation
      fullDescription
      category
      homepage
      statusSince
      status
      repository{
        name
      }
      latestVersion {
        architectures
        hasParameters
        networks {
          name
          description
          networkType
        }
        networkMode
        publishedDate
        requiredMemory
        requiredStorage
        version
      }
    }
  }
}

`;
export const GET_MANAGEMENT_SERVICES = gql`
	query GET_MANAGEMENT_SERVICES(
    $page: Int
    $itemsPerPage: Int
    $recipeIds: [ID!]
    $orderBy: RecipeOrderBy
    $direction: Direction
    $search: ShortString
    $categories: [String!]
    $repositories: [String]
    $status: [RecipeStatusEnum!]

    $askForPagination: Boolean!,
  ) {
    management {
      availableRepositories
    	recipeCategories
      recipes(
        page: $page,
        itemsPerPage: $itemsPerPage,
        recipeIds: $recipeIds,
        orderBy: $orderBy,
        direction: $direction,
        search: $search,
        categories: $categories,
        repositories: $repositories,
        status: $status,
      ) {
        pageInfo @include(if: $askForPagination){
          count
          itemsPerPage
          maxItemsPerPage
          currentPage
          numPages
        }
        results {
          id
          name
          countPods
          displayName
          description
          documentation
          fullDescription
          category
          homepage
          statusSince
          status
          latestVersion {
            hasParameters
            version
            publishedDate
            architectures
            networkMode
          }
          repository {
            name
          }
          logo{
            id
            mimeType
            data
          }
        }
      }
    }
  }
`;

export const GET_MANAGEMENT_POD = gql`
  query GET_MANAGEMENT_POD(
    $podId: Int!
  ) {
    management {
      availableRepositories
      pod(
        podId: $podId
      ) {
        id
        created
        enabled
        statusDetail
        statusSince
        isUpToDate
        url
        actions {
          action
          enabled
        }
        volumes {
          id
          name
          sizeKilobytes
          actions {
            action
            enabled
          }
        }
        createdBy {
          id
          firstname
          lastname
          email
        }
        units
        device {
          id
          location
          deviceId
          operatingMode
          isRebooting
          isAlive
          organization{
            id
            name
            slug
          }
          network{
            id
            location
          }
          configurationTemplate{
            name
            displayName
            operatingMode
          }
          usage
        }
        restarts
        containers {
          id
          name
          status
          restarts
        }
        project {
          id
          name
          status
        }
        task {
          id
          status
        }
        networkMode
        networks{
          id
          name
          type
          description
          exposedPorts{
            number
            protocol
          }
          devicePort{
            id
            macAddress
            label
          }
          vlanAccess{
            ip
            host{
              macAddr
            }
            vlan{
              id
              vid
              accessPolicy
              name
            }
          }
        }
        parameters{
          default
          description
          immutable
          key
          label
          required
          type
          value
        }
        currentVersion{
          hasParameters
          version
          publishedDate
          architectures

          recipe {
            id
            name
            countPods
            displayName
            description
            documentation
            fullDescription
            category
            homepage
            statusSince
            status
            repository{
              name
            }
            latestVersion {
              hasParameters
              version
              publishedDate
              architectures
              requiredStorage
              requiredMemory
            }
          }
        }        
      }
    }
  }

`;

export const GET_MANAGEMENT_PODS = gql`
  query GET_MANAGEMENT_PODS(
    $page: Int
    $itemsPerPage: Int
    $podIds: [Int!]
    $projectId: UUID
    $deviceIds: [String!]
    $orderBy: PodOrderBy
    $direction: Direction
    $search: ShortString
    $enabled: Boolean,
    $organizationSlug: ShortString,
    $networkId: UUID,
    $statusDetails: [PodStatusDetailEnum!]
    $deviceOperatingModes: [DeviceOperatingMode!]
    $recipeId: ID
    $repositories: [String]
    $upToDate: [PodUpToDateEnum!]
    $askForPagination: Boolean!,
  ) {
    management {
      availableRepositories
      pods(
        page: $page
        itemsPerPage: $itemsPerPage
        podIds: $podIds
        projectId: $projectId
        deviceIds: $deviceIds
        orderBy: $orderBy
        search: $search
        enabled: $enabled
        direction: $direction
        statusDetails: $statusDetails
        recipeId: $recipeId
        deviceOperatingModes: $deviceOperatingModes
        repositories: $repositories
        upToDate: $upToDate
        organizationSlug: $organizationSlug
        networkId: $networkId
      ) {
        pageInfo @include(if: $askForPagination){
          count
          itemsPerPage
          maxItemsPerPage
          currentPage
          numPages
        }
        results {
          id
          created
          enabled
          url
          statusDetail
          statusSince
          isUpToDate
          actions {
            action
            enabled
          }
          createdBy {
            id
            firstname
            lastname
            email
          }
          restarts
          containers {
            id
            name
            status
            restarts
          }
          device {
            id
            location
            deviceId
            operatingMode
            configurationTemplate{
              name
              displayName
              operatingMode
            }
            podSystem {
              status
              message
              refreshTime
              storageDisk {
                linuxId
                bytesUsed
                bytesTotal
                parentDiskName
                name
                partNumber
              }
            }
            organization{
              id
              name
              slug
            }
            network{
              id
              location
            }
            usage
            isRebooting
            isAlive
          }
          project {
            id
            name
            status
          }
          task {
            id
            status
          }
          parameters{
            default
            description
            immutable
            key
            label
            required
            type
            value
          }
          currentVersion{
            hasParameters
            version
            publishedDate
            architectures

            recipe {
              id
              name
              countPods
              displayName
              description
              documentation
              fullDescription
              category
              homepage
              statusSince
              status
              repository{
                name
              }
              latestVersion {
                hasParameters
                version
                publishedDate
                architectures
              }
            }            
          }
        }
      }
    }
  }

`;
