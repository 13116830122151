import gql from "graphql-tag";

/*
	UIPreferenceScopeEnum: GLOBAL/ORGANIZATION/PROJECT/USER
*/

export const UPDATE_PREFERENCES = gql`
	mutation (
	  $key: String!
	  $scope: UIPreferenceScopeEnum!
	  $value: JSONString!
	  $projectId: UUID
	) {
	  uiPreferenceUpdate(
	    key: $key
	    scope: $scope
	    value: $value
	    projectId: $projectId
	  ) {
	    userError {
	      code
	      message
	      details {
	        field
	        messages
	      }
	    }
	    uiPreference {
	      key
	      scope
	      value
	    }
	  }
	}
`;