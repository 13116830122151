import gql from "graphql-tag";

export const GET_MANAGEMENT_ROGUEWIFIS = gql`
  query GET_MANAGEMENT_ROGUEWIFIS(
    $page: Int,
		$itemsPerPage: Int,
		$organizationSlug: ShortString,
    $networkId: UUID, 
		$orderBy: RogueSSIDOrderBy,
		$direction: Direction,
		$search: ShortString,
		$fromDate: DateTime,
		$toDate: DateTime,
		$rogueSsidIds: [UUID!],
		$isWhitelisted: Boolean,
		$bands: [WifiBandType!],
		$ruleMatchTypes: [MatchTypesEnum],

		$askForPagination: Boolean!,
  ){    
   	management {
	    rogueSsids(
		    page: $page,
				itemsPerPage: $itemsPerPage,
				organizationSlug: $organizationSlug,
				networkId: $networkId,
				orderBy: $orderBy,
				direction: $direction,
				search: $search,
				fromDate: $fromDate,
				toDate: $toDate,
				rogueSsidIds: $rogueSsidIds,
				isWhitelisted: $isWhitelisted,
				ruleMatchTypes: $ruleMatchTypes,
				bands: $bands,
	    ) {
    		pageInfo @include(if: $askForPagination){
    			count
    			itemsPerPage
    			maxItemsPerPage
    			currentPage
    			numPages
    		}
	      results {
	        id
	        seenCount
	        organization {
	        	slug
	        	id
	          name
	        }
	        network {
	          id
	          location
	        }
	        name
	        ruleValue
	        ruleMatchType
	        firstSeen
	        lastSeen
	        band
	        bss{
	        	id
	        	bssid
	        	flags
	        }	        
	        isWhitelisted
	      }
	    }
	  }
	}
`;