import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';

import { Icon } from 'components/Icon';

export default class GlobalMessage extends Component {

  render () {
    // the style contains only the margin given as offset
    // options contains all alert given options
    // message is the alert message...
    // close is a function that closes the alert
    const { options, message, close } = this.props;
    
    return (
      <Message
      	info={options.type === 'info'}
      	positive={options.type === 'success'}
      	negative={options.type === 'error'}
      	onDismiss={close}
        className="globalmessage"
      >
		    <Message.Content>
          {options.type === 'info' && <Icon name="info circle"/>}
          {options.type === 'success' && <Icon name="check circle"/>}
          {options.type === 'error' &&  <Icon name="times circle"/>}
	        {message}
		    </Message.Content>
      </Message>
    )
  }
}