import React from 'react';
import { useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Container, Message } from 'semantic-ui-react';
import _ from 'lodash';

import { getPages, getSupportUrls } from 'tools/getUrls.js';

import { SecondaryButton } from 'components/Buttons/SecondaryButton';

export function ErrorExpandable({error, resetErrorBoundary, children}) {

  const history = useHistory();

  const isAdmin = _.get(history,"location.pathname") && _.get(history,"location.pathname").indexOf("/support") === 0;

  if(error) {
    return (
      <Container as="section">
        <br/>
        <Message negative>
          <Message.Header>
          	<FormattedMessage id="generic.errors.message" />
          </Message.Header>
          <Message.Content>
            <p>({error.message || error })</p>
            <SecondaryButton
              i18nId="generic.buttons.retry"
              onClick={() => {window.location.reload()}}
            />
            <SecondaryButton
              i18nId="generic.backHome"
              onClick={() => {window.location = isAdmin ? getSupportUrls().main : getPages().selectOrganization}}
            />
          </Message.Content>
        </Message>
      </Container>
    );
  }

  if(children) return this.props.children;

  return null;
}