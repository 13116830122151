import gql from "graphql-tag";

export const GET_MANAGEMENT_USERS = gql`
  query GET_MANAGEMENT_USERS(
    $page: Int
		$itemsPerPage: Int
		$search: ShortString
		$permissions: [UserPermission!]
		$isActive: Boolean
		$organizationIds: [UUID!]
		$orderBy: UserOrderBy
		$direction: Direction
		$askForPagination: Boolean!,
  ){    
   	management {
	    users(
		    page: $page
				itemsPerPage: $itemsPerPage
				search: $search
				permissions: $permissions
				isActive: $isActive
				organizationIds: $organizationIds
				orderBy: $orderBy
				direction: $direction
	    ) {
    		pageInfo @include(if: $askForPagination){
    			count
    			itemsPerPage
    			maxItemsPerPage
    			currentPage
    			numPages
    		}
	      results {
	      	id 
	      	firstname
	      	lastname
	      	isPrivileged
	      	isAuditor
	      	isActive
	      	createdOn
          email
          organizationUsers{
          	id
		      	firstname
		      	lastname
		      	organization{
		      		id
		      		slug
		      		name
		      	}
          	phonenumber
          	picture{
          		id
          		medium
          	}
          }
          updatedBy{
		      	id 
		      	firstname
		      	lastname
		      	email
		      	isPrivileged
		      	isAuditor
		      	organizationUsers{
		      		id
		      	}
		      }
					updatedOn      	
	      }
	    }
	  }
	}
`;

export const GET_MANAGEMENT_USER = gql`
  query GET_MANAGEMENT_USER(
    $userId: UUID!
  ){
   	management {
	    user(
		    userId: $userId
	    ) {
      	id 
      	firstname
      	lastname
      	isPrivileged
      	isAuditor
        email
        createdOn
        organizationUsers{
        	id
	      	firstname
	      	lastname
	      	organization{
	      		id
	      		slug
	      		name
          	enableOperations
	      	}
        	phonenumber
        	picture{
        		id
        		medium
        	}
        	notificationPreferences {
	          id
	          section
	          group
	          enabled
	          emailEnabled
	        }
        }  
	    }
	  }
	}
`;

export const GET_MANAGEMENT_USERHISTORY  =gql`
  query GET_MANAGEMENT_USER(
    $page: Int,
		$itemsPerPage: Int,
		$search: ShortString
		$email: String,
		$action: [UserChangeType],
		$orderBy: UserChangeOrderBy,
		$direction: Direction,

		$askForPagination: Boolean!,
  ){
   	management {
   		userChanges(
	   		page: $page,
				itemsPerPage: $itemsPerPage,
				search: $search,
				email: $email,
				action: $action,
				orderBy: $orderBy,
				direction: $direction,
			){
				pageInfo @include(if: $askForPagination){
    			count
    			itemsPerPage
    			maxItemsPerPage
    			currentPage
    			numPages
    		}
	      results {
	      	accessChanges{
	      		type
						organization{
							id
							name
						},
						firstname
						lastname
						phonenumber
						pictureUuid
						changedFields
	      	}
					changedFields
					firstname
					lastname
					isPrivileged
					type
          updatedBy{
		      	id 
		      	firstname
		      	lastname
		      	email
		      	isPrivileged
		      	isAuditor
		      	organizationUsers{
		      		id
		      	}
		      }
					updatedOn
					user{
						id
						email
						firstname
						lastname
					}
	      }
			}
   	}
  }
`;