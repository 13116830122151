import React, { Component } from "react";
import { FormattedMessage } from 'react-intl';
import { Loader, Segment } from 'semantic-ui-react';

export class LoadingMessage extends Component {
	render(){
		return (
			<Segment>
				{
					this.props.message || (
						<Loader active inline='centered' content={<React.Fragment><FormattedMessage id="generic.loading"/>...</React.Fragment>} />
					)
				}
			</Segment>
		)
	}
}

/////////////////////////////////////////////////////////////////////
// Modifier le loading + icon + style comme loader du seleteur de range des stats
/////////////////////////////////////////////////////////////////////