import { Component } from "react";
import store from 'tools/SimpleStore.js';

export default class UpdateToken extends Component {

	static updateToken(callback){
		if(window.keycloak.authenticated) {
		  window.keycloak.updateToken(60).success(function(refreshed) {
		    if (refreshed) {
		      console.log('Token was successfully refreshed');
		    }
		  }).error(function() {
		      if(callback && typeof callback === "function") callback();
		      window.keycloak.clearToken();
		      console.log("logout")
		      window.keycloak.logout({redirectUri:window.location.href, locale: store("locale") || "en"});
		  });			
		}
	}

	render(){
		UpdateToken.updateToken();
		return this.props.children;
	}
}