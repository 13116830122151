import React, { Component } from 'react';
import store from 'tools/SimpleStore.js';

import Home from 'routes/Home';

class AuthenticationValidator extends Component {

  static AvailableOnlyLogOff = class AvailableOnlyLogOffClass extends Component{
    render(){
      const kc = window.keycloak;
      if(!kc.token) {
        return React.cloneElement(this.props.children);
      }
      return <Home connected />;
    }
  }

  render(){
    const kc = window.keycloak;
    if (kc.token) {
      return React.cloneElement(this.props.children, {tokenParsed: window.keycloak.tokenParsed});
    } else {
      window.location = kc.createLoginUrl({redirectUrl: this.props.url || window.location.href, locale: store("locale") || "en"})
    }
    return null;
  }
}

export default AuthenticationValidator;