import gql from "graphql-tag";

export const GET_MANAGEMENT_PROJECTS = gql`
	query GET_MANAGEMENT_PROJECTS(
		$projectIds: [UUID!], 
		$status: [ProjectStatusEnum!], 
		$orderBy: ProjectOrderBy,
		$search: ShortString
		$page: Int
		$itemsPerPage: Int
		$direction: Direction

		$askForPagination: Boolean!,
	) {
		management {
			projects(
				projectIds: $projectIds
				status: $status
				orderBy: $orderBy
				search: $search
				page: $page
				itemsPerPage: $itemsPerPage
				direction: $direction
			) {
				pageInfo @include(if: $askForPagination){
					count
					itemsPerPage
					maxItemsPerPage
					currentPage
					numPages
				}
				results {
					id
					numId
					name
					status
					description
					lastUpdate
					updatedBy {
						lastname
						firstname
						email
					}
					created
					owner {
						lastname
						firstname
						email
					}
				}
			}
		}
	}
`;
export const GET_MANAGEMENT_PROJECT = gql`
	query GET_MANAGEMENT_PROJECT(
		$projectId: UUID!
	) {
		management {
			project(
				projectId: $projectId
			) {
				countPacketCaptures
				countPortScans
				created
				description
				devices{
					canBeRemoved
					isRemoved
					removed
					added
					network{
						id
						location
						organization{
							id
							name
							slug
						}
					}
					device{
						id
						deviceId
						location
						publicIp
						operatingMode
            configurationTemplate{
              name
              displayName
              operatingMode
            }
            usage
	          isAlive
	          isRebooting
						currentFirmware{
							version
						}
						kernelVersion
						availableFirmware{
							version
						}
	          network{	            
			        router{
			          id
			          location
			        }
	          }	           
			      actions {
			        action
			        enabled
			        disabledReason
			      }
			      ports{
			        id
			        label
			        usage
			        accessVlan{
			          id
			          vid
			          name
			        }
			      }
					}
					note
				}
				id
				lastUpdate
				name
				numId
				owner{
					lastname
					firstname
					email
				}
				status
				updatedBy{
					lastname
					firstname
					email
				}
			}
		}
	}
`;
