import React from 'react';
import { PrimaryButton } from 'components/Buttons/PrimaryButton';

export function SecondaryButton(props){

  return (
    <PrimaryButton
      {...props}
      primary={false}
      secondary
      type={props.type}
      disabled={props.loading || props.disabled || null}
      size={props.size === 'mini' ? 'mini' : null}
    />
  );
}